<template>
    <div class="mt-5">
        <loading :loading="loading" spinner-class="justify-center">
            <b-table striped :items="items" :fields="fields" class="stripped-table">
                <template #cell(fullname)="data">
                    <div v-if="data.item.user" class="flex items-center">
                        <div class="mr-2">
                            <a href="#">
                                <img :src="data.item.user.images !== '' && data.item.user.images !== null ? data.item.user.images : require('@/assets/images/avatars/avatar-1.jpg')" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                            </a>
                        </div>
                        <div>
                            <h5 class="font-bold color-dark">{{ data.item.user.fullname }}</h5>
                            <span>{{ data.item.user.no_induk }}</span>
                        </div>
                    </div>
                </template>
                <template #cell(date)="data">
                    <div v-for="mentor in data.item.mentors" :key="mentor.id">
                        <span v-if="mentor.id === user.id">{{ formatDate(mentor.date) }}</span>
                    </div>
                </template>
                <template #cell(time)="data">
                    <div v-for="mentor in data.item.mentors" :key="mentor.id">
                        <span v-if="mentor.id === user.id">{{ formatTime(mentor.date) }}</span>
                    </div>
                </template>
                <template #cell(action)="data">
                    <div>
                        <router-link :to="{ name: 'DetailMahasiswaJadwalBimbingan', params: { id: data.item.id } }" class="btn btn-primary">Lihat Detail</router-link>
                    </div>
                </template>
            </b-table>
        </loading>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'JadwalBimbingan',
        computed: {
            ...mapState('skripsi', { data: 'mahasiswa.mahasiswaBimbingan', loading: 'loading' }),
            ...mapState('auth', { user: 'user' })
        },
        methods: {
            ...mapActions('skripsi', { fetchJadwalBimbingan: 'fetchJadwalBimbingan' })
        },
        data () {
            return {
                items: [],
                fields: [
                    {
                        key: 'fullname',
                        label: 'Nama Mahasiswa',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'date',
                        label: 'Tanggal Pengajuan',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'time',
                        label: 'Jam',
                        tdClass: 'align-middle'
                    },
                    {
                        key: 'action',
                        label: 'Action',
                        tdClass: 'align-middle'
                    }
                ]
            }
        },
        mounted () {
            this.fetchJadwalBimbingan()
                .then(res => {
                    this.items = res
                })
                .catch(e => console.log(e))
        }
    }
</script>
